// src/components/Accordion.js
'use client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MinusIcon, PlusIcon } from '../../assets/Icons';
import { getLanguage } from '../../utils/language';

const AccordionItem = ({ title, children, borderb = true }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={borderb ? `border-b` : ''}>
			<button className={`w-full flex justify-between items-center pt-6  text-left ${isOpen ? '  ' : ' pb-8 '}`} onClick={() => setIsOpen(!isOpen)}>
				<span className=' font-medium text-lg'>{title}</span>
				<span>{isOpen ? <MinusIcon /> : <PlusIcon />}</span>
			</button>
			{isOpen && <div className='  pt-2 pb-8'>{children}</div>}
		</div>
	);
};

const Accordion = () => {
	const locale = getLanguage();
	const { t } = useTranslation('Index');

	return (
		<div className='w-full max-w-[768px] mx-auto  rounded'>
			<AccordionItem title={t('faqs_title1')}>
				<p>{t('faqs_desc1')}</p>
			</AccordionItem>
			<AccordionItem title={t('faqs_title2')}>
				<p>{t('faqs_desc2')}</p>
			</AccordionItem>
			<AccordionItem title={t('faqs_title3')}>
				<p>{t('faqs_desc3')}</p>
			</AccordionItem>
			<AccordionItem title={t('faqs_title4')}>
				{locale === 'uz' ? (
					<>
						<p>1. №260325, Amal qilish muddati:22.04.2024 dan 22.04.2029 gacha </p>
						<p>2. №UZ.SMT.01.0068.80499091, Amal qilish muddati:28.07.2022 dan 28.07.2025 gacha </p>
					</>
				) : (
					<>
						<p>1. №260325, Срок действия: с 22.04.2024 по 22.04.2029 </p>
						<p>2. №UZ.SMT.01.0068.80499091, Срок действия: с 28.07.2022 по 28.07.2025. </p>
					</>
				)}
			</AccordionItem>
			<AccordionItem borderb={false} title={t('faqs_title5')}>
				<p>{t('faqs_desc5')}</p>
			</AccordionItem>
		</div>
	);
};

export default Accordion;
