'use client';
import { Dropdown } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../utils/language';

const Translate = () => {
	const { i18n } = useTranslation();

	const locate = getLanguage();
	const onLanguageHandle = newLang => {
		i18n.changeLanguage(newLang);
		window.localStorage.setItem('language', newLang);
	};
	return (
		<>
			<Dropdown
				label=''
				renderTrigger={() => (
					<div className='cursor-pointer'>
						{locate === 'uz' ? (
							<div className={`flex items-center gap-1 `}>
								<img src='/images/uz.png' alt='' className='w-[20px] h-[20px]' />
								<span>Uz</span>
							</div>
						) : (
							<div className={`flex items-center gap-1 `}>
								<img src='/images/ru.svg' alt='' className='w-[20px] h-[20px]' />
								<span>Ру</span>
							</div>
						)}
					</div>
				)}
			>
				<Dropdown.Item className={`${locate === 'ru' ? ' bg-blue text-white ' : ''}`} onClick={() => onLanguageHandle('ru')}>
					Ру
				</Dropdown.Item>
				<Dropdown.Item className={`${locate === 'uz' ? ' bg-blue text-white' : ''}`} onClick={() => onLanguageHandle('uz')}>
					Uz
				</Dropdown.Item>
			</Dropdown>
		</>
	);
};

export default Translate;
