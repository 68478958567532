import React, { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Home from '../components/main_pages/Home';
import Layout from '../sections/layoutSections/Layout';
// import NotFount '../components/main_pages/NotFount'
import NotFount from '../components/main_pages/NotFount';
// const Home = lazy(() => import('../components/main_pages/Home'));
// const NotFount = lazy(() => import('../components/main_pages/NotFount'));

const Routers = () => {
	return (
		<Routes>
			<Route
				element={
					<Layout>
						<Outlet />
					</Layout>
				}
			>
				<Route path='/'>
					<Route
						index
						element={
							<Suspense>
								<Home />
							</Suspense>
						}
					/>
				</Route>
			</Route>
			<Route
				path='*'
				element={
					<Suspense>
						<NotFount />
					</Suspense>
				}
			/>
		</Routes>
	);
};
export default Routers;
