import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Translate from './translate';

const Header = () => {
	const { t } = useTranslation('Index');
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const toggleDrawer = () => {
		setIsOpen(prevState => !prevState);
	};
	return (
		<div className=''>
			<div className='bg-[#F2F4F7] py-[15px]'>
				<div className='container flex justify-between text-sm  text-[#475467] flex-wrap gap-2'>
					<div className='flex items-center gap-1 md:gap-2   '>
						<img src='/images/location.svg' alt='' />

						<Link to={'https://www.google.com/maps?q=41.230360,69.150666'} target='blank' className='line-clamp-1	'>
							{t('location')}
						</Link>
					</div>
					<div className='flex gap-[7px] md:gap-4 flex-wrap'>
						<div className='flex items-center  gap-1 md:gap-2  '>
							<img src='/images/clock.svg' alt='' />
							<span className=''>{t('time_work')}</span>
						</div>
						<div className='flex items-center gap-1 md:gap-2 '>
							<img src='/images/call-center.svg' alt='' />
							<Link to={'tel:+998957952020'} target='blank' className='hover:text-blue'>
								+998 (95) 795-20-20
							</Link>
						</div>
						<Translate />
					</div>
				</div>
			</div>
			<div className='container my-4 flex justify-between items-center'>
				<div className='flex items-center gap-24'>
					<Link to={'/'}>
						<img src='/images/logo.svg' alt='' />
					</Link>
					<ul className=' hidden md:flex gap-9  font-medium text-lg'>
						<li>
							<Link to={'/'} className=' hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'>
								{t('home')}
							</Link>
						</li>
						<li>
							<a href={'#about'} className=' hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'>
								{t('about')}
							</a>
						</li>
						<li>
							<a href={'#services'} className=' hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'>
								{t('services')}
							</a>
						</li>
						<li>
							<a href={'#dilvery'} className=' hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'>
								{t('dilvery')}
							</a>
						</li>
					</ul>
				</div>
				<div className='flex items-center gap-2'>
					<Button
						title={t('contact_with')}
						className=' px-6 '
						onClick={() => {
							navigate('#contact');
						}}
						link='#contact'
					/>
					<img src='/images/hamburger.png' alt='' className='w-9 h-9 md:hidden cursor-pointer' onClick={toggleDrawer} />
				</div>
			</div>
			<Drawer open={isOpen} onClose={toggleDrawer} direction='right' className='!w-full'>
				<div>
					<div className='flex justify-between p-6'>
						<img
							src='/images/close.png'
							alt=''
							className='w-7 cursor-pointer'
							onClick={() => {
								setIsOpen(false);
							}}
						/>
						<div></div>
					</div>
					<ul className=' px-10 flex flex-col gap-5'>
						<li>
							<Link
								onClick={() => {
									setIsOpen(false);
								}}
								to={'/'}
								className=' block p-4  rounded-[28px] w-full bg-[#F9FAFB] hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'
							>
								{t('home')}
							</Link>
						</li>
						<li>
							<a
								onClick={() => {
									setIsOpen(false);
								}}
								href={'#about'}
								className='block p-4  rounded-[28px] w-full bg-[#F9FAFB] hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'
							>
								{t('about')}
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setIsOpen(false);
								}}
								href={'#services'}
								className='block p-4  rounded-[28px] w-full bg-[#F9FAFB] hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'
							>
								{t('services')}
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setIsOpen(false);
								}}
								href={'#dilvery'}
								className='block p-4  rounded-[28px] w-full bg-[#F9FAFB] hover:text-blue ease-in-out delay-150 transition-all duration-75  hover:scale-110'
							>
								{t('dilvery')}
							</a>
						</li>
					</ul>
				</div>
			</Drawer>
		</div>
	);
};

export default Header;
