import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
	return (
		<div>
			<Header />
			{children}
			<Link to={'tel:+998957952020'} target='blank' className='fixed md:hidden  left-[25px] bottom-[25px] w-[60px] h-[60px] p-4 rounded-full bg-white shadow-shadowcall'>
				<img src='/images/call.svg' alt='' />
			</Link>
			<Footer />
		</div>
	);
};

export default Layout;
