const Button = ({ title = '', className = '', onClick = () => {}, type = 'button', link }) => {
	return (
		<a href={link}>
			<button type={type} className={`${className} bg-blue py-4 text-white rounded-[28px]  text-base  max-h-[53px]  flex justify-center items-center`}>
				{title}
			</button>
		</a>
	);
};

export default Button;
