import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'swiper/css';
import Routers from './routers/Routers';
import WebProvider from './utils/WebProvider';
import createStore from './utils/store';

const App = () => {
	const store = createStore();
	return (
		<Provider store={store}>
			<WebProvider>
				<BrowserRouter>
					<Routers />
				</BrowserRouter>
			</WebProvider>
			<Toaster />
		</Provider>
	);
};
export default App;
