'use client';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { CallIcon, EmailIcon, OfficeIcon } from '../../assets/Icons';
import Accordion from '../../components/ui/Accardion';
import Button from '../../components/ui/Button';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
	const [loading, setLoading] = useState(true);
	const [obj, setObj] = useState({});
	const [err, setErr] = useState({});
	const [loadingform, setLoadingForm] = useState(false);
	const { t } = useTranslation('Index');
	// const router = useRouter();
	const navigate = useNavigate();
	const inputMaskRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	const Finish = e => {
		e.preventDefault();

		let tt = true,
			err = {};
		if (!obj?.name) {
			err = { ...err, name: true };
			tt = false;
		}
		if (!obj?.company_name) {
			err = { ...err, company_name: true };
			tt = false;
		}

		if (!(obj?.phone_number?.replace(/-/g, '')?.replace(/\+/g, '')?.replace(/\(/g, '')?.replace(/\)/g, '')?.replace(/\s/g, '')?.replace(/_/g, '')?.toString()?.length > 11)) {
			err = { ...err, phone_number: true };
			tt = false;
		}

		if (tt) {
			setLoadingForm(true);
			axios.post('https://api.omadpropan.uz/contacts/', {
				name: obj?.name,
				company_name: obj?.company_name,
				email: obj?.email,
				phone_number: obj?.phone_number?.replace(/-/g, '')?.replace(/\(/g, '')?.replace(/\)/g, '')?.replace(/\s/g, '')?.replace(/_/g, ''),
			})
				.then(res => {
					toast.success(t('success'));
					setObj({ name: '', company_name: '', email: '', phone_number: '' });
				})
				.catch(err => {
					console.log(err, 'err');
					toast.error(t('error'));

					setObj({ name: '', company_name: '', email: '', phone_number: '' });
				})
				.finally(() => {
					setLoadingForm(false);
				});
		} else {
			setErr(err);
		}
	};

	const HandleChange = event => {
		setObj({ ...obj, [event.target.name]: event.target.value });
		setErr({ ...err, [event.target.name]: false });
	};

	if (loading) {
		return (
			<div className='h-screen  bg-white z-50 absolute top-0 bottom-0 right-0  left-0 flex justify-center items-center  overflow-hidden'>
				<img src='/images/logo.svg' alt='' />
			</div>
		);
	}

	return (
		<div>
			<div className='container'>
				<div className=' rounded-3xl w-full  object-cover relative'>
					<div
						className=' rounded-3xl  absolute top-0 bottom-0 left-0 right-0'
						style={{ background: 'linear-gradient(270deg, rgba(0, 0, 0, 0) -27.52%, rgba(0, 0, 0, 0.8) 48.13%)' }}
					/>

					<div className=' absolute text-white lg:w-2/3 lg:ml-[76px] p-6  sm:p-8 md:p-16 flex justify-center items-center h-full'>
						<div className='flex flex-col gap-4'>
							<h1 className=' font-semibold text-xl sm:text-3xl  md:text-[42px] md:leading-[50px]'>{t('banner_h1')}</h1>
							<p className='  text-sm sm:text-base md:text-xl font-normal'>{t('banner_p')} </p>
							<div className=' hidden sm:flex gap-6 '>
								<Button
									title={t('contact_with')}
									className='px-4 md:px-6  py-3 md:py-4   md:text-base '
									link='#contact'
									// onClick={() => {
									// 	navigate('#contact');
									// }}
								/>
								<Button
									title={t('find_price')}
									className=' !bg-white !text-black px-4 md:px-6 py-3 md:py-4'
									onClick={() => {
										navigate('#price');
									}}
									link='#price'
								/>
							</div>
						</div>
					</div>
					<img src='/images/section-1.jpg' alt=' ' className=' rounded-3xl max-h-[578px] w-full' />
				</div>
			</div>
			<div className='container py-16  lg:flex justify-between gap-16 items-center' id='contact'>
				<div className='flex flex-col gap-4 lg:w-2/3 lg:mb-0 mb-8'>
					<h2 className=' text-2xl md:text-4xl  font-bold text-black'>{t('our_advantages')}</h2>
					<p className='text-[#475467]  text-[20px]  font-medium'>{t('our_advantages_p')}</p>
				</div>
				<form onSubmit={Finish} className='flex flex-col gap-6 rounded-2xl  backdrop-blur-[24px] border border-[#EAECF0] bg-[#F9FAFB] p-9'>
					<h2 className=' text-center text-2xl font-semibold  text-black'>{t('contact_title')}</h2>
					<div className='flex flex-col gap-3'>
						<input onChange={HandleChange} type='text' name='name' value={obj?.name} className=' rounded-[28px] px-6 py-4 border-[#EAECF0]' placeholder={t('placeholder_1')} />
						<span className=' text-red text-xs px-3 '>{err?.name === true ? t('required') : null}</span>
						<input
							onChange={HandleChange}
							type='text'
							name='company_name'
							value={obj?.company_name}
							className=' rounded-[28px] px-6 py-4 border-[#EAECF0]'
							placeholder={t('placeholder_2')}
						/>
						<span className=' text-red text-xs px-3 '>{err?.company_name === true ? t('required') : null}</span>
						<input onChange={HandleChange} type='text' name='email' value={obj?.email} className='rounded-[28px] px-6 py-4 border-[#EAECF0]' placeholder={t('placeholder_3')} />
						<InputMask
							formatChars={{ b: '[0-9]' }}
							mask='+998 (bb) bbb-bb-bb'
							maskChar=''
							name='phone_number'
							value={obj?.phone_number}
							ref={inputMaskRef}
							placeholder={t('placeholder_4')}
							className='rounded-[28px] px-6 py-4 border-[#EAECF0]'
							onChange={HandleChange}
						/>
						<span className=' text-red text-xs px-3 '>{err?.phone_number === true ? t('required') : null}</span>
						<span className=' text-center text-sm'>{t('contact_span')}</span>
					</div>
					<Button type='submit' title={loadingform ? '...loading' : t('submit')} className='w-full' />
				</form>
			</div>
			<div className='bg-[#F9FAFB]  relative ' id='about'>
				<div className=' pt-16 md:pt-24  pb-40 text-center  container flex flex-col gap-[20px]'>
					<h2 className='text-2xl md:text-4xl font-bold text-black'>{t('about_title')}</h2>
					<p className='text-[#475467]  md:text-xl font-medium'>{t('about_desc')}</p>
				</div>
				<img src='/images/olov.svg' alt='' className=' hidden md:block absolute top-0 ' />
			</div>
			<div className='container '>
				<div className='relative'>
					<img src='/images/about.jpg' alt='' className='w-full max-h-[300px]  md:max-h-[531px] top-[-64px] -translate-y-16 object-cover rounded-xl' />
					<img src='/images/red-olov.svg' alt='' className=' absolute right-0 bottom-0 -translate-y-16 h-2/3 md:h-[90%]' />
				</div>
			</div>
			<div className='bg-[#F9FAFB] '>
				<div className='container py-6 md:py-16'>
					<div className='flex   flex-col  lg:flex-row justify-between items-center gap-16'>
						<div className='flex flex-col gap-[40px] py-9 lg:w-1/2'>
							<div className='flex flex-col gap-6'>
								<h4 className=' text-2xl md:text-black font-bold'>{t('order_registration')}</h4>
								<p className='text-[#475467]  font-medium text-[20px]'>{t('order_registration_desc')}</p>
							</div>
							<Button
								title={t('contact_with')}
								className='w-full  md:w-[280px] '
								onClick={() => {
									navigate('#contact');
								}}
								link='#contact'
							/>
						</div>
						<img src='/images/contents.png' alt='' />
					</div>
				</div>
			</div>
			<div className='container py-24 flex flex-col gap-8' id='services'>
				<div className='flex flex-col gap-4'>
					<h2 className='text-black font-bold text-2xl md:text-4xl'>{t('how_we_working')}</h2>
					<p className='text-xl text-[#475467]'>{t('how_we_working_p')}</p>
				</div>
				<div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-6 '>
					{[
						{
							icon: '/images/laptop.svg',
							h4: t('how_we_working_card_title_1'),
							p: t('how_we_working_card_desc_1'),
						},
						{
							icon: '/images/user-check.svg',
							h4: t('how_we_working_card_title_2'),
							p: t('how_we_working_card_desc_2'),
						},
						{
							icon: '/images/document.svg',
							h4: t('how_we_working_card_title_3'),
							p: t('how_we_working_card_desc_3'),
						},
						{
							icon: '/images/truck.svg',
							h4: t('how_we_working_card_title_4'),
							p: t('how_we_working_card_desc_4'),
						},
					]?.map((item, index) => {
						return (
							<div key={index} className='p-6 bg-[#F9FAFB] rounded-xl'>
								<div className='mb-9 bg-[#175CD3] p-4 rounded-[10px]  w-fit'>
									<img src={item?.icon} alt='' />
								</div>
								<h4 className='mb-2 text-black font-bold text-xl'>{item?.h4}</h4>
								<p className='text-[#475467] text-base'>{item?.p}</p>
							</div>
						);
					})}
				</div>
			</div>
			<div className='container'>
				{/* <div className='flex justify-center'>
					<div className='text-center flex flex-col gap-[20px] mb-16 xl:w-1/2'>
						<h2 className='text-2xl md:text-4xl text-black  font-bold'>{t('stock_h1')}</h2>
						<p className='text-[20px] text-[#475467]'>{t('stock_desc')}</p>
					</div>
				</div> */}
				<div style={{ position: 'relative', width: '100%', paddingBottom: '40%' }} className=' object-cover'>
					<img
						src='/images/mockup.jpg'
						alt=''
						className=' rounded-2xl object-cover'
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
						}}
					/>
				</div>
				<div className='grid grid-cols-1  md:grid-cols-2  justify-center my-12 md:mt-[80px]  md:mb-24'>
					{[
						// { h4: t('stock_card_title1'), p: t('stock_card_desc1') },
						{ h4: t('stock_card_title2'), p: t('stock_card_desc2') },
						{ h4: t('stock_card_title3'), p: t('stock_card_desc3') },
					]?.map((item, index) => {
						return (
							<div
								key={index}
								className={
									index === 0
										? `border-t-0 md:border-t-[4px] md:border-[#175CD3] px-4 pt-6  flex justify-center `
										: 'border-t-0 md:border-t-[4px] md:border-[#F2F4F7] px-4 pt-6  flex justify-center '
								}
							>
								<div className='flex flex-col gap-2  text-center'>
									<h4 className='text-black font-bold text-xl'>{item?.h4}</h4>
									<p className='font-normal text-base'>{item?.p} </p>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className='bg-[#F9FAFB]'>
				{/* <div className='container flex justify-between flex-wrap items-center py-12 md:py-16'>
					<h2 className=' text-black font-bold text-2xl md:text-4xl'>{t('slider_title')}</h2>
					<Button
						title={t('contact_with')}
						className='px-6'
						onClick={() => {
							navigate('#contact');
						}}
					/>
				</div>
				<div className=' pb-12 md:pb-16'> <TestimonialSlider /></div> */}
			</div>
			<div className='container py-12 md:py-16' id='dilvery'>
				<div className='flex  justify-center'>
					<div className='text-center flex flex-col gap-[20px] mb-16 xl:w-1/2'>
						<h2 className='text-2xl md:text-4xl text-black  font-bold'> {t('advantages_title')}</h2>
						<p className='text-[20px] text-[#475467]'>{t('advantages_desc')} </p>
					</div>
				</div>
				<div className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center'>
					{[
						{ icon: '/images/wallet.svg', h4: t('advantages_card_title1'), p: t('advantages_card_desc1') },
						{
							icon: '/images/checkmark.svg',
							h4: t('advantages_card_title2'),
							p: t('advantages_card_desc2'),
						},
						{
							icon: '/images/setting.svg',
							h4: t('advantages_card_title3'),
							p: t('advantages_card_desc3'),
						},
					]?.map((item, index) => (
						<div key={index} className='flex justify-center'>
							<div className='text-center'>
								<div className='flex  justify-center mb-4'>
									<img src={item?.icon} alt='' className='text-center' />
								</div>
								<h4 className='mb-2  text-xl font-bold text-black'>{item?.h4}</h4>
								<p className=' text-lg text-[#475467] font-normal'>{item?.p}</p>
							</div>
						</div>
					))}
				</div>
				<div className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center mt-16'>
					{[
						{ icon: '/images/agreement.svg', h4: t('advantages_card_title4'), p: t('advantages_card_desc4') },
						{
							icon: '/images/sale-tag.svg',
							h4: t('advantages_card_title5'),
							p: t('advantages_card_desc5'),
						},
						{
							icon: '/images/container-truck.svg',
							h4: t('advantages_card_title6'),
							p: t('advantages_card_desc6'),
						},
					]?.map((item, index) => (
						<div key={index} className='flex justify-center'>
							<div className='text-center'>
								<div className='flex  justify-center mb-4'>
									<img src={item?.icon} alt='' className='text-center' />
								</div>
								<h4 className='mb-2  text-xl font-bold text-black'>{item?.h4}</h4>
								<p className=' text-lg text-[#475467] font-normal'>{item?.p}</p>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className='bg-[#F9FAFB]'>
				<div className='container py-12 md:py-16'>
					<div className='  flex-col flex md:flex-row justify-between gap-16 items-center'>
						<div className='flex flex-col gap-[40px]  md:w-1/2 '>
							<div className='flex flex-col gap-6'>
								<h4 className='text-2xl md:text-4xl text-black font-bold'>{t('more_15_years_title')}</h4>
								<p className='text-[#475467]  font-medium text-[20px]'>{t('more_15_years_desc')}</p>
							</div>
							<Button
								title={t('contact_with')}
								className=' w-full md:w-[280px] '
								onClick={() => {
									navigate('#contact');
								}}
								link='#contact'
							/>
						</div>

						<div className='md:w-[45%] w-full'>
							<div className='flex justify-center'>
								{/* <Swiper pagination={true} navigation={true} modules={[Pagination, Navigation]} className='mySwiper'>
									<SwiperSlide>
										<iframe allowFullScreen='' scrolling='no' className=' w-full h-[400px] ' src='/pdf/ЛИЦЕНЗИЯ.pdf'></iframe>
									</SwiperSlide>
									<SwiperSlide>
										<iframe allowFullScreen='' scrolling='no' className=' w-full h-[400px] ' src='/pdf/view.pdf'></iframe>
									</SwiperSlide>
								</Swiper> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container' id='price'>
				<div className='flex-col  flex md:flex-row  justify-between gap-8 md:gap-16 items-center  pt-12 md:pt-24 pb-8'>
					<div className='flex flex-col gap-6 md:gap-[40px]  md:w-1/2 '>
						<div className='flex flex-col gap-6'>
							<h4 className='text-2xl md:text-4xl text-black font-bold'>{t('propan_in_cylinders_title')}</h4>
							<p className='hidden md:block text-[#475467]  font-medium text-[20px]'>{t('propan_in_cylinders_desc')}</p>
							<div className=' hidden md:block text-black font-bold text-2xl md:text-4xl'>{t('propan_in_cylinders_summa')}</div>
						</div>
						<Button
							title={t('contact_with')}
							className=' w-full md:w-[280px] '
							onClick={() => {
								navigate('#contact');
							}}
							link='#contact'
						/>
					</div>
					<div className='bg-[#F2F4F7] w-full md:w-1/2 rounded-2xl '>
						<div className=' flex justify-center'>
							<img src='/images/propan.png' alt='' />
						</div>
					</div>
					<div className='flex flex-col gap-6'>
						<div className='md:hidden block text-black font-bold text-3xl md:text-4xl'>{t('propan_in_cylinders_summa')}</div>{' '}
						<p className='md:hidden block text-[#475467]  font-medium text-[20px]'>{t('propan_in_cylinders_desc')}</p>
					</div>
				</div>
			</div>
			<div className='my-16'>
				<div className='container flex flex-col gap-16'>
					<div className='flex  flex-col  justify-center text-center gap-[20px]'>
						<h4 className=' font-bold text-2xl md:text-4xl'>{t('faqs_title')}</h4>
						<p className=' font-medium text-xl text-[#475467]'>{t('faqs_desc')}</p>
					</div>
					<Accordion />
				</div>
				<div className='container py-9  bg-[#F9FAFB] rounded-2xl'>
					<div className='flex flex-col gap-8 text-center'>
						<div className='flex justify-center'>
							<img className=' max-w-fit' src='/images/avatar-group.svg' alt='' />
						</div>
						<div className='flex  flex-col gap-2'>
							<h5 className=' font-semibold text-[20px]'>{t('still_have_questions_title')}</h5>
							<p className=' text-lg '>{t('still_have_questions_desc')}</p>
						</div>
						<div className='flex justify-center'>
							<Button
								title={t('contact_with')}
								className=' px-6  max-w-fit'
								onClick={() => {
									navigate('#contact');
								}}
								link='#contact'
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='text-center flex flex-col gap-[20px] mb-8'>
					<h2 className='text-2xl md:text-4xl text-black  font-bold'>{t('our_location')}</h2>
					<p className='text-[20px] text-[#475467]'>{t('our_location_desc')}</p>
				</div>

				<div style={{ position: 'relative', width: '100%', paddingBottom: '41%' }}>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3000.605810986832!2d69.14809107605171!3d41.23035997132021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDEzJzQ5LjMiTiA2OcKwMDknMDIuNCJF!5e0!3m2!1suz!2s!4v1720080074415!5m2!1suz!2s'
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
						}}
						allowFullScreen=''
						loading='lazy'
						referrerPolicy='no-referrer-when-downgrade'
					></iframe>
				</div>
			</div>
			<div className='container py-16'>
				<div className='flex flex-wrap gap-8  justify-center lg:justify-between'>
					<div className='flex flex-col gap-[20px] text-center md:w-[384px]'>
						<div className=' flex justify-center'>
							<div className=' w-max rounded-full p-6 border border-[#B2DDFF] shadow-shadows12'>
								<EmailIcon />
							</div>
						</div>
						<div>
							<h4 className=' text-black text-[20px] font-bold'>{t('email')}</h4>
							<p className='text-[#475467] text-lg'>{t('email_desc')}</p>
						</div>
						<Link to={'mailto:hi@omadpropan.uz'} className=' text-blue text-base font-semibold'>
							hi@omadpropan.uz
						</Link>
					</div>
					<div className='flex flex-col gap-[20px] text-center md:w-[384px]'>
						<div className=' flex justify-center'>
							<div className=' w-max rounded-full p-6 border border-[#B2DDFF] shadow-shadows12'>
								<OfficeIcon />
							</div>
						</div>
						<div>
							<h4 className=' text-black text-[20px] font-bold'>{t('office')}</h4>
							<p className='text-[#475467] text-lg'>{t('office_desc')}</p>
						</div>
						<Link to={'https://www.google.com/maps?q=41.230360,69.150666'} className=' text-blue text-base font-semibold'>
							{t('location')}
						</Link>
					</div>
					<div className='flex flex-col gap-[20px] text-center w-[384px]'>
						<div className=' flex justify-center'>
							<div className=' w-max rounded-full p-6 border border-[#B2DDFF] shadow-shadows12'>
								<CallIcon />
							</div>
						</div>
						<div>
							<h4 className=' text-black text-[20px] font-bold'>{t('phone')}</h4>
							<p className='text-[#475467] text-lg'>{t('time_work')}</p>
						</div>
						<Link to={'tel:+998957952020'} className='text-blue text-base font-semibold'>
							+998 (95) 795-20-20
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
