import { useTranslation } from 'react-i18next';
import { InstagramIcon, TelegramIcon } from '../..//assets/Icons';
import { Link } from 'react-router-dom';

const Footer = () => {
	const { t } = useTranslation('Index');

	return (
		<div className='bg-blue text-white'>
			<div className='container py-12 flex justify-between items-center flex-wrap gap-6'>
				<div className='sm:min-w-[30%] '>
					<ul className='flex gap-6 '>
						<li>
							<Link to={'https://t.me/omadpropan'} target='blank'>
								<TelegramIcon />
							</Link>
						</li>
						<li>
							<Link to={'https://www.instagram.com/omad_propan/'} target='blank'>
								<InstagramIcon />
							</Link>
						</li>
					</ul>
				</div>
				<div>
					<img src='/images/logo-white.svg' alt='' />
				</div>
				<div className=' text-base'>
					© {new Date().getFullYear()} {t('all_rights_reserved')}
				</div>
			</div>
		</div>
	);
};

export default Footer;
